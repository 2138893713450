// Money formattaing is simple
export function makeMoney( value ){
  return new Intl.NumberFormat('un-US', { style: 'currency', currency: 'USD' }).format(value)
}

// Determine the best "Back" link
export function getBackLink(){
  if( typeof localStorage !== 'undefined' && localStorage['lastListingPage'] !== undefined ){
    return localStorage['lastListingPage'].replace(window.location.origin,'')
  }
  return '/';
}

// Adds a special data-attribute to the body for fancy styling
export function setSpecialStyle(value = ''){
  if( typeof document !== 'undefined' ){
    document.body.dataset.specialStyle = value
  }
}

// Clean for slug
 export function cleanSlug(name){
  return name.toLowerCase().replace(/[^a-z0-9]+/g,'-').replace(/-$/,'');
}
