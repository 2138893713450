import * as React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet";

import "../styles/_main.scss"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet titleTemplate="Cost of Gaming - %s" defaultTitle="Cost of Gaming">
        <meta charSet="utf-8" />
        <title>Cost of Gaming</title>
        <body className="" />
      </Helmet>
      <Header />
        <main className="flex">{children}</main>
      <Footer />
    </>
  )
}
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 