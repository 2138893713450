import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getBackLink } from '../utilities.js'

const Header = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      markdownRemark(fileAbsolutePath: {regex: "/data/other-copy.md$/"}) {
        frontmatter {
          header_message
        }
      }
    }
  `)

  function getBestTitleLink(){
    if( typeof window !== 'undefined' && window.location.href.match(/details/) !== null ){
      return getBackLink();
    }else{
      return '/';
    }
  }

  return (
    <header className="text-white text-shadow">
      <div className="container">
        <div className="row py-md-2 py-1">
          <h1 className="col-lg-5 col-12 text-lg-start align-self-center text-center ">
            <Link className="text-decoration-none text-white" to={getBestTitleLink()}>{ data.site.siteMetadata.title }</Link>
          </h1>
          <p  className="col-lg-7 col-12 text-lg-end   align-self-center text-center m-none">{ data.markdownRemark.frontmatter.header_message }</p>
        </div>
      </div>
    </header>
  )
}
 export default Header
 