import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        buildTime(formatString: "dddd, MMMM Do YYYY")
        fields {
          inflateDataUpdated
        }
      }
    }
  `)
  return (
    <footer className="text-center">
      <div className="container">
        <div className="row py-md-3 py-2">
          <div className="col">
            <p className="problems">Missing or incorrect information? <a href="mailto:problems@truecostofgaming.com?subject=Problem." target="_blank" rel="noreferrer">Let me know</a>.</p>
            <p className="my-3">
              Last updated {data.site.buildTime}.<br/>
              Inflation data up-to-date as of  {data.site.fields.inflateDataUpdated}.</p>
            <p className="build-with my-3">
              This site was made with the following tools:<br/>
              <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">Gatsby</a>
              <span className="div">&ndash;</span>
              <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">Bootstrap</a>
              <span className="div">&ndash;</span>
              <a href="https://www.npmjs.com/package/deinflation" target="_blank" rel="noreferrer">deinflation</a>
            </p>
            <p className="boring-footer pt-1 pb-3">True Cost of Gaming ©{new Date().getFullYear()}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
 export default Footer
 